import React from "react";

const AppLogo = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="992.126px"
    height="255.118px"
    viewBox="0 0 992.126 255.118"
    enableBackground="new 0 0 992.126 255.118"
  >
    <g>
      <g>
        <g>
          <g>
            <path
              fill="#EDEDED"
              d="M141.043,163.012l9.599-16.55h17.781l-4.06-33.224l-36.916,64.11h-22.457l51.312-83.921h24.672
					l12.735,83.921h-21.165l-1.906-14.336H141.043z"
            />
            <path
              fill="#EDEDED"
              d="M222.38,177.348c-5.824,0-9.742-1.64-11.752-4.922c-1.682-2.666-2.01-6.276-0.984-10.829l11.566-52.42
					c1.108-4.716,3.22-8.408,6.337-11.075c3.527-3.117,8.019-4.676,13.475-4.676h4.552l-18.703,83.921H222.38z M270.124,172.918
					c3.282-2.748,5.455-6.522,6.522-11.321l2.399-10.583H258.68l-1.784,7.691c-0.246,0.821-0.76,1.231-1.538,1.231h-19.503
					l-3.938,17.412h24.979C262.27,177.348,266.679,175.872,270.124,172.918z M267.233,112.069l-1.784,7.813h20.426l2.4-10.705
					c1.065-5.045,0.471-8.941-1.785-11.69c-2.257-2.707-5.906-4.061-10.952-4.061h-24.918l-3.938,17.412h19.503
					C267.13,110.839,267.479,111.249,267.233,112.069z"
            />
            <path
              fill="#EDEDED"
              d="M371.334,93.427l-3.938,17.412h-24.426l-14.766,66.509h-20.796l14.766-66.509h-24.426l3.876-17.412
					H371.334z"
            />
            <path
              fill="#EDEDED"
              d="M405.296,93.427l-18.642,83.921h-20.796l18.643-83.921H405.296z"
            />
            <path
              fill="#EDEDED"
              d="M443.874,149.477l-14.644,27.871h-0.861l-11.874-83.921h21.165L443.874,149.477z M452.549,177.348
					h-18.027l45.467-83.921h22.457L452.549,177.348z"
            />
            <path
              fill="#EDEDED"
              d="M529.517,110.839l-10.829,49.098h41.592l-3.876,17.412h-62.387l18.642-83.921h62.264l-3.938,17.412
					H529.517z M559.788,143.693H527.24l3.938-17.35h32.486L559.788,143.693z"
            />
            <path
              fill="#EDEDED"
              d="M654.044,177.348h-24.795l3.876-17.412h17.904c0.737,0,1.251-0.41,1.538-1.231l10.275-46.636
					c0.286-0.82-0.042-1.231-0.985-1.231h-22.949l-14.704,66.509h-20.796l18.642-83.921h50.636c4.223,0,7.465,1.477,9.72,4.43
					c2.338,2.994,3.015,6.563,2.03,10.705l-11.874,53.65c-0.984,4.225-3.118,7.712-6.398,10.459
					C662.555,175.79,658.515,177.348,654.044,177.348z"
            />
            <path
              fill="#EDEDED"
              d="M729.659,147.877h-11.075l3.938-17.412h21.78c0.738,0,1.25-0.409,1.538-1.23l3.754-17.166
					c0.245-0.82-0.083-1.231-0.985-1.231h-26.886l-14.704,66.509h-20.796l18.642-83.921h53.281c5.045,0,8.695,1.354,10.952,4.061
					c2.297,2.749,2.891,6.644,1.785,11.69l-5.169,23.565c-0.821,3.528-2.552,6.635-5.198,9.321c-2.646,2.687-5.732,4.461-9.26,5.322
					l8.183,29.963h-21.965L729.659,147.877z"
            />
            <path
              fill="#EDEDED"
              d="M812.718,93.427l-18.642,83.921H773.28l18.643-83.921H812.718z"
            />
            <path
              fill="#EDEDED"
              d="M851.295,149.477l-14.644,27.871h-0.861l-11.874-83.921h21.165L851.295,149.477z M859.971,177.348
					h-18.027l45.467-83.921h22.457L859.971,177.348z"
            />
            <path
              fill="#EDEDED"
              d="M936.939,110.839l-10.829,49.098h41.592l-3.876,17.412h-62.387l18.642-83.921h62.264l-3.938,17.412
					H936.939z M967.21,143.693h-32.547l3.938-17.35h32.486L967.21,143.693z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              fill="#009FE3"
              d="M369.263,29.543c2.755-0.45,6.859-0.788,11.412-0.788c5.622,0,9.557,0.844,12.256,2.98
					c2.249,1.799,3.486,4.441,3.486,7.927c0,4.835-3.429,8.152-6.69,9.332v0.169c2.642,1.068,4.104,3.598,5.06,7.083
					c1.181,4.273,2.361,9.22,3.092,10.682h-8.77c-0.618-1.068-1.518-4.16-2.642-8.826c-1.012-4.723-2.642-6.016-6.128-6.072h-2.586
					v14.898h-8.489V29.543z M377.753,45.847h3.373c4.273,0,6.803-2.136,6.803-5.453c0-3.486-2.361-5.229-6.297-5.285
					c-2.08,0-3.261,0.169-3.879,0.281V45.847z"
            />
            <path
              fill="#009FE3"
              d="M447.631,50.907h-13.942v8.995h15.573v7.027h-24.174V29.037h23.387v7.027h-14.786v7.871h13.942V50.907z"
            />
            <path
              fill="#009FE3"
              d="M477.594,66.929V29.037h10.007l7.871,13.886c2.249,3.991,4.498,8.714,6.184,12.986h0.169
					c-0.562-5.003-0.731-10.119-0.731-15.797V29.037h7.871v37.891h-8.995l-8.096-14.617c-2.249-4.048-4.722-8.939-6.578-13.38
					l-0.169,0.056c0.225,5.004,0.337,10.345,0.337,16.529v11.412H477.594z"
            />
            <path
              fill="#009FE3"
              d="M546.18,36.234h-10.176v-7.196h29.121v7.196h-10.344v30.695h-8.602V36.234z"
            />
            <path
              fill="#009FE3"
              d="M634.835,57.203l-2.699,9.726h-8.883l11.581-37.891h11.244l11.75,37.891h-9.22l-2.923-9.726H634.835z
					 M644.448,50.794l-2.361-8.039c-0.675-2.249-1.349-5.06-1.911-7.309h-0.112c-0.562,2.249-1.124,5.116-1.743,7.309l-2.249,8.039
					H644.448z"
            />
            <path
              fill="#009FE3"
              d="M747.045,65.861c-1.574,0.787-5.116,1.63-9.726,1.63c-13.099,0-19.845-8.152-19.845-18.946
					c0-12.93,9.22-20.126,20.689-20.126c4.441,0,7.814,0.9,9.332,1.687l-1.743,6.802c-1.743-0.731-4.16-1.405-7.196-1.405
					c-6.802,0-12.087,4.104-12.087,12.537c0,7.589,4.497,12.368,12.143,12.368c2.586,0,5.453-0.563,7.14-1.237L747.045,65.861z"
            />
            <path
              fill="#009FE3"
              d="M784.541,57.203l-2.699,9.726h-8.883l11.581-37.891h11.244l11.75,37.891h-9.22l-2.923-9.726H784.541z
					 M794.154,50.794l-2.361-8.039c-0.675-2.249-1.349-5.06-1.911-7.309h-0.112c-0.562,2.249-1.124,5.116-1.743,7.309l-2.249,8.039
					H794.154z"
            />
            <path
              fill="#009FE3"
              d="M835.08,29.543c2.755-0.45,6.859-0.788,11.412-0.788c5.622,0,9.557,0.844,12.256,2.98
					c2.249,1.799,3.486,4.441,3.486,7.927c0,4.835-3.429,8.152-6.69,9.332v0.169c2.642,1.068,4.104,3.598,5.06,7.083
					c1.181,4.273,2.361,9.22,3.092,10.682h-8.77c-0.618-1.068-1.518-4.16-2.642-8.826c-1.012-4.723-2.642-6.016-6.128-6.072h-2.586
					v14.898h-8.489V29.543z M843.569,45.847h3.373c4.273,0,6.802-2.136,6.802-5.453c0-3.486-2.361-5.229-6.297-5.285
					c-2.08,0-3.261,0.169-3.879,0.281V45.847z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              fill="#009FE3"
              d="M24.734,118.391c1.295,6.413,2.907,14.25,4.151,20.673c4.236,21.885,5.12,42.754,10.106,56.098
					c2.363,6.323,8.949,9.086,16.581,9.431c14.326,1.212,732.786,0.64,917.245,0.423l5.153,3.102c0,0-926.341,8.994-936.388,5.138
					c-11.658-4.497-12.375-21.336-13.978-36.992c-0.112,0.013-0.054,3.644-0.165,3.656c0,17.881-1.642,38.251,1.236,46.911
					c1.998,6.824,11.557,7.493,17.571,7.74c9.316,0.382,15.572,0.833,25.482,0.833c6.897,0,10.326-1.999,12.658-5.33
					c0,2.741,0.765,8.7-2.411,11.563c-1.503,0.949-9.318,1.093-10.663,1.094c-18.476,0.019-18.914,0.003-37.39,0
					c-13.49,0.695-16.444-10.762-16.446-16.155c-0.014-26.232,0.124-56.735,0-82.966c-0.014-2.898,0.612-4.559,3.63-4.883
					c3.442-0.37,3.594-2.354,3.629-5.552c0.09-8.255-0.021-9.854-0.466-14.701C24.734,118.391,24.267,118.476,24.734,118.391z"
            />
          </g>
          <g>
            <path
              fill="#009FE3"
              d="M53,186.807c1.047,0.576,2.243,0.983,3.118,1.754c6.764,5.957,14.463,3.939,16.303-4.831
					c0.757-3.61,0.237-7.962-1.109-11.431c-2.173-5.6-10.924-5.785-15.401-0.737c-0.737,0.831-1.165,1.935-1.899,2.77
					c-0.377,0.429-1.112,0.543-1.685,0.799c-0.238-0.683-0.889-1.657-0.646-2.001c1.517-2.149,2.898-4.611,4.948-6.124
					c5.357-3.955,14.957-2.637,18.945,2.209c5.439,6.608,5.396,15.82-0.1,21.617c-4.905,5.173-14.079,5.847-19.693,1.284
					c-1.426-1.159-2.43-2.835-3.628-4.274C52.436,187.497,52.718,187.152,53,186.807z"
            />
          </g>
          <g>
            <path
              fill="#009FE3"
              d="M239.477,12.552c-36.456-0.044-94.167-1.535-128.424,4.411c-3.128,0.543-6.502,2.026-8.893,4.082
					C84.98,35.82,75.22,54.867,70.489,67.093c2.223,1.494,5.996-5.022,10.198-10.119c14.131-17.138,15.472-18.234,24.489-24.73
					c3.631-2.616,8.407-4.085,13.311-5.196c25.618-5.804,77.289-8.044,120.26-7.795C280.466,19.496,275.932,12.596,239.477,12.552z"
            />
          </g>
          <g>
            <path
              fill="#009FE3"
              d="M105.817,128.782c-16.986-0.708-32.623-0.086-48.947-0.496c-6.45-0.162-6.662-4.747-7.661-8.994
					c-0.871-3.7,2.636-6.372,4.528-6.967c10.967-3.449,21.692-5.394,26.398-6.579c0,0,2.338-0.422,0.136-1.209
					c-7.02,0.702-19.781,3.156-33.06,7.288c-6.35,1.976-6.004,3.085-5.69,9.908c0.11,2.395,0.241,4.791,0.266,7.187
					c0.033,3.256,1.246,4.718,4.86,4.648c16.778-0.326,33.561-0.397,50.343-0.54c4.298-0.037,8.596-0.006,13.056-0.006
					C110.041,129.944,105.817,128.782,105.817,128.782z"
            />
          </g>
          <g>
            <path
              fill="#009FE3"
              d="M67.379,73.548c-3.956,1.491-6.733,1.109-9.616-1.242c-1.505-1.228-2.698-2.86-3.974-4.334
					c-5.758-6.656-12.215-4.97-14.745-4.46c-10.388,2.071-24.974,4.736-27.98,11.569c-2.576,8.807-1.149,15.318,3.163,15.673
					c6.255,0.579,16.641-3.011,25.51-4.377c-0.034-0.248-0.069-0.497-0.103-0.745c-7.96,0.533-23.891,4.377-24.195,1.621
					c-0.281-3.079-0.688-5.857-0.688-8.635c0-0.775,0.276-3.022,1.724-4.104c6.94-3.417,18.637-6.519,26.29-7.274
					c2.802-0.033,2.975,0.338,4.944,2.106c3.2,2.874,6.133,6.046,9.052,8.987c-18.967,8.662-31.12,28.528-32.71,37.954
					c3.441-4.494,6.677-9.611,10.776-13.904C51.181,83.447,63.277,80.029,74.406,74.98C74.406,74.98,85.342,67.979,67.379,73.548z"
            />
          </g>
          <path
            fill="#009FE3"
            d="M81.828,104.538c0,0,22.513-2.4,23.989,24.244l4.227,4.241
				C110.044,133.023,116.318,100.389,81.828,104.538z"
          />
          <path
            fill="#009FE3"
            d="M57.202,83.107c0,0,24.172-17.622,195.203-17.622c0-0.098-88.104-11.756-180.038,6.717L57.202,83.107z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default AppLogo;
